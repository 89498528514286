.row{
	max-width:1140px;
	padding: 0 20px;
}
table {
    background: #fff;
    margin-bottom: 1.25rem;
    border: solid 1px #ddd;
    table-layout: auto;
}
table thead {
    background: #F5F5F5;
}
table tr.even, table tr.alt, table tr:nth-of-type(even) {
    background: #F9F9F9;
}
table tr td{
	font-size: 0.875rem;
	line-height: 1.125rem;
}


/*    Header    */

header{
	width: 100%;
	height: 130px;
	background: transparent;
}
header nav{
	margin-top: 60px;
}
header .top-bar ul{
	list-style: none;
	background-color: transparent;
}
header .top-bar-left .name{
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;

}
header .top-bar-section ul li a{
	color: #ffffff;
	font-size: 14px;
	text-transform: uppercase;
	margin-right: 30px;
	letter-spacing: .5px;
}
header .top-bar-section ul li:last-child a{
	margin-right: 0;
}
header .top-bar-section li:not(.has-form) a:not(.button){
	-webkit-transition:all .2s linear;
	     -o-transition:all .2s linear;
	        transition:all .2s linear;
}
header .top-bar-section li:not(.has-form) a:not(.button):hover{
	color: #fbdd90;
}
.top-bar,
.top-bar-section li:not(.has-form) a:not(.button),
.top-bar-section li:not(.has-form) a:not(.button):hover,
.top-bar-section ul li,
.top-bar.expanded .title-area,
.no-js .top-bar-section ul li:hover > a{
	background: transparent;
	height: auto;
	line-height:normal;
	padding: 0;
}
.top-bar .name,
.top-bar .name h1,
.top-bar .name h2,
.top-bar .name h3,
.top-bar .name h4,
.top-bar .name span,
.top-bar .name p{
	line-height: normal;
	height: auto;
	font-size: 1.375rem;
}


/*    Hero    */
.hero{
	width: 100%;
	height: 540px;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover; 
	text-shadow: 0 0 5px rgba(33,33,33,0.6);
}
#body.littlerock .hero{
	background-color: #3b527c;
	@include breakpoint(medium){
		background-image: url('/assets/images/littlerock-hero.jpg');
		height: 768px;
	}
}
#body.applevalley .hero{
	background-color: #3b527c;
	@include breakpoint(medium){
		background-image: url('/assets/images/applevalley-hero.jpg');
	}
}
#body.kanis .hero{
	background-color: #3b527c;
	@include breakpoint(medium){
		background-image: url('/assets/images/kanis-hero.jpg');
	}
}
#body.maumelle .hero{
	background-color: #3b527c;
	@include breakpoint(medium){
		background-image: url('/assets/images/maumelle-hero.jpg');
	}
}
#body.riverdale .hero{
	background-color: #3b527c;
	@include breakpoint(medium){
		background-image: url('/assets/images/riverdale-hero.jpg');
	}
}

.hero .caption{
	margin-top: 4%;
}
.hero .caption h1.mean_cap{
	font-size: 60px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 70px;
}
.hero .caption h2.sub_cap{
	font-weight: lighter;
	font-size: 60px;
	color: #fff;
	text-transform: uppercase;
	line-height: 70px;
	margin-bottom: 50px;
}

#body.privacy .hero {
  height: 280px;
}
#body.privacy #privacy {
   padding: 2em 0;
}

#body.privacy .caption {
  margin-top: 0px;
}


/*    Locations    */
.locations{
	padding: 110px 0;
}

.locations .mean_title,
.locations .sub_title{
	text-align: center;
	color: #454545;
	font-family: Helvetica, Arial, sans-serif;
}
.locations .mean_title{
	font-size: 30px;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
	text-transform: uppercase;
}
.locations .sub_title{
	font-size: 22px;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: lighter;
	text-transform: lowercase;
}
.locations .locations_list{
	margin-top: 100px;	
}
.locations .locations_list .serv_icon{
	margin-bottom: 60px;
}
.locations .locations_list p{
	color: #454545;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 30px;
}
.locations .locations_list, .locations .locations_list ul{
	color: #454545;
	font-family: "raleway-regular", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 30px;
}
.locations .title{
	color: #454545;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 20px
}
.locations .locations_list em {
    color: #c1c1c1;
    font-size: 0.8em;
}



/*    Fancy Button    */
.btn_fancy{
	display: inline-block;
	position: relative;
	text-align: center;
	color: #454545;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 16px 36px;
	border-radius: 4px;
	border: 1px dashed #000000;
	background: transparent;
	margin-bottom: 4em;
	-webkit-transition:all .2s ease-out;
	     -o-transition:all .2s ease-out;
	        transition:all .2s ease-out;
}
.btn_fancy:hover,
.btn_fancy:focus{
	color: #454545;
	border-radius: 0px;
	background-color:#fbdd90;
}

/*    Footer    */
footer{
	padding: 80px 0;
	background: #535353;
	font-family: Helvetica, Arial, sans-serif;
}
footer .copyrights h2{
	text-decoration: none;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
}
footer .copyrights p{
	color: #c5c5c5;
	font-size: 12px;
	letter-spacing: .5px;
}
footer .copyrights a,
footer .copyrights a:link,
footer .copyrights a:visited {
	font-size: 12px;
	letter-spacing: .5px;
}
footer .copyrights a:hover,
footer .copyrights a:active {
  color: #fff;
  text-decoration: underline;
}
footer .social a{
	display: inline-block;
	color: #fff;
	font-size: 14px;
	letter-spacing: 0.5px;
}
footer .contact{
	color: #fff;
}
footer p{
	margin: 0;
	padding: 0;
}





/*    Responsive CSS    */
@media only screen and (max-width: 40em){
	.top-bar .top-bar-section{
		display: none;
	}
	.hero .caption h1.mean_cap,
	.hero .caption h2.sub_cap{
		font-size: 45px;
		line-height: 60px;
	}
	footer .copyrights{
		width: 100%;
		text-align: center;
		margin-bottom: 60px;
	}
	footer .social,
	footer .contact{
		width: 100%;
		text-align: center;
		float: none;
		margin: 0;
	}
	footer .contact{
		margin-top: 60px;
	}
}
